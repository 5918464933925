import React, { useState } from 'react'
import ImageGallery from '../components/ImageGallery';

const EventItem = ({ id, lang, title, description, ts, location, locationUrl }) => {
  const [image, setImage] = useState();
  import(`../assets/images/events/${id}.png`).then((module) => {
    console.log(module.default)
    setImage(module.default)
  }).catch(() => setImage('placeholder'));

  return (
    <div style={{ position: 'relative' }}>
      <div style={{ display: 'inline-block', width: 'calc(33% - 15px)', marginRight: '15px', verticalAlign: 'top' }}>
        {image === 'placeholder' ? (
          <div
            style={{
              display: 'inline-block',
              width: '100%',
              aspectRatio: '2/3',
              backgroundColor: 'lightgray'
            }}
          />
        ) : (
          <ImageGallery images={[image]} />
        )}
      </div>
      <div style={{
        display: 'inline-block',
        width: '66%',
        verticalAlign: 'top',
        paddingTop: '15px'
      }}>
        <h3>{title[lang]}</h3>
        <p>{description[lang].split("\n").map((text) => (<>{text}<br /></>))}</p>
        <p><b>Date and time:</b> {new Date(ts).toLocaleString()}</p>
        <p>
          <b>Location:</b> <a href={locationUrl}>{location}</a>
        </p>
      </div>
    </div>
  )
}

export default EventItem
