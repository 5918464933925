const compareISODates = (a, b) => {
  let date1 = new Date(a.ts);
  let date2 = new Date(b.ts);

  if (date1 > date2) {
    return 1;
  } else if (date1 < date2) {
    return -1
  } else {
    return 0;
  }
}

export const organizeEvents = (events) => {
  const futureEvents = [];
  const pastEvents = [];
  events.forEach((event) => {
    if (new Date() < new Date(event.ts)) {
      futureEvents.push(event);
    } else {
      pastEvents.push(event);
    }
  })
  return {
    futureEvents: futureEvents.sort(compareISODates),
    pastEvents: pastEvents.sort(compareISODates),
  }
}