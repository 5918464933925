import React from 'react'
import Layout from '../components/Layout'
import events from '../assets/events.json'
import EventItem from '../components/EventItem';
import Collapsible from '../components/Collapsible';
import { organizeEvents } from '../utils/eventUtils';

const Events = ({ location }) => {
  const { futureEvents, pastEvents } = organizeEvents(events)
  return (
    <Layout location={location} lang={'ca'}>
      <section id="one">
        <h2>
          Propers tallers
        </h2>
        <p>
          En aquest apartat hi trobareu els propers tallers que es duran a terme. Per qualsevol consulta, no dubteu en posar-vos en contacte a <a href="mailto:musicoterapia@saracillan.com">musicoterapia@saracillan.com</a>.
        </p>

        {futureEvents.length ?
          futureEvents.map((event) => (
            <EventItem key={event.id} {...event} lang={'ca'} />
          ))
          : (
            'Actualment no hi ha tallers programats'
          )}
      </section>

      <section id="two">
        <Collapsible openedButtonText={'Veure tallers passats'} closedButtonText={'Amagar tallers passats'}>
          {pastEvents.map((event) => (
            <EventItem key={event.id} {...event} lang={'ca'} />
          ))}
        </Collapsible>
      </section>
    </Layout>
  )
}

export default Events
