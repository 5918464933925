import React, { useState } from 'react'

const Collapsible = ({ openedButtonText, closedButtonText, children }) => {
  const [collapsed, setCollapsed] = useState(true);

  return (
    <>
      <button
        className="button"
        style={{ marginBottom: '15px' }}
        onClick={() => setCollapsed(!collapsed)}
      >
        {collapsed ? openedButtonText : closedButtonText}
      </button>
      {!collapsed && (
        <div>
          {children}
        </div>
      )}
    </>
  )
}

export default Collapsible
